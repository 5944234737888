import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";

import { AuthFlow, LoggedInFlow } from "components/layouts";
import { RequirePerm } from "components/guards";
import { CLOUD_MAP_TYPES, FEATURE, PERMISSION_TYPES } from "store/types";
import { Loading } from "components/ui";
import {
  ACCEPT_INVITATION_ROUTE,
  ACCOUNTS_ROUTE,
  CLOUD_MAP_ACCOUNTS_ROUTE,
  DASHBOARD_ROUTE,
  DASHBOARD_HISTORICAL_ROUTE,
  DEPENDABOT_ROUTE,
  EVENTS_ROUTE,
  INVENTORY_ROUTE,
  INVENTORY_BY_RESOURCE_ID_ROUTE,
  LOGIN_ORG_ROUTE,
  LOGIN_ROUTE,
  RULES_ROUTE,
  NOTIFICATIONS_ROUTE,
  USERS_ROUTE,
  SIGNUP_CREATE_ORG,
  SIGNUP_ROUTE,
  SIGNUP_VALIDATE_INVITATION_ROUTE,
  UNDO_REMEDIATION_ROUTE,
  ISSUE_BY_ID_ROUTE,
  ISSUES_BY_RESOURCE_ID_ROUTE,
  ISSUES_BY_RULE_ID_ROUTE,
  EVENT_BY_ID_ROUTE,
  INTEGRATIONS_ROUTE,
  CLOUD_MAP_ASSETS_ROUTE,
  COMPLIANCE_ROUTE,
  COMPLIANCE_BY_ID_ROUTE,
  DASHBOARD_BY_ID_ROUTE,
} from "consts";

const LazyIndexRoute = lazy(() => import("routes/IndexRoute"));
const LazySignInRoute = lazy(() => import("routes/SignInRoute"));
const LazySignInOrgRoute = lazy(() => import("routes/SignInOrgRoute"));
const LazyAcceptInviteRoute = lazy(() => import("routes/AcceptInviteRoute"));
const LazySignUpRoute = lazy(() => import("routes/SignUpRoute"));
const LazySignUpOrgRoute = lazy(() => import("routes/SignUpOrgRoute"));
const LazyInviteCodeRoute = lazy(() => import("routes/InviteCodeRoute"));
const LazyDashboardRoute = lazy(() => import("routes/DashboardRoute"));
const LazyDashboardByIdRoute = lazy(() => import("routes/DashboardRoute/DashboardByIdRoute"));
const LazyAccountsRoute = lazy(() => import("routes/AccountsRoute"));
const LazyCloudMapRoute = lazy(() => import("routes/CloudMapRoute"));
const LazyDependabotRoute = lazy(() => import("routes/DependabotRoute"));
const LazyEventsRoute = lazy(() => import("routes/EventsRoute"));
const LazyEventRoute = lazy(() => import("routes/EventsRoute/EventRoute"));
const LazyInventoryRoute = lazy(() => import("routes/InventoryRoute"));
const LazyIntegrationsRoute = lazy(() => import("routes/IntegrationsRoute"));
const LazyInventoryByResourceIdRoute = lazy(
  () => import("routes/InventoryRoute/InventoryByResourceIdRoute/inventoryByResourceIdRoute"),
);
const LazyUsersRoute = lazy(() => import("routes/UsersRoute"));
const LazyNotificationsRoute = lazy(() => import("routes/NotificationsRoute"));
const LazyRulesRoute = lazy(() => import("routes/RulesRoute"));
const LazyNotFoundRoute = lazy(() => import("routes/NotFoundRoute"));
const LazyUndoRemediationRoute = lazy(() => import("routes/DashboardRoute/UndoRemediationRoute"));
const LazyIssueRoute = lazy(() => import("routes/DashboardRoute/IssueRoute"));
const LazyIssuesByResourceIdRoute = lazy(() => import("routes/DashboardRoute/IssuesByResourceIdRoute"));
const LazyIssuesByRuleIdRoute = lazy(() => import("routes/DashboardRoute/IssuesByRuleIdRoute"));
const LazyIssuesComplianceRoute = lazy(() => import("routes/ComplianceRoute"));
const LazyIssuesComplianceByIdRoute = lazy(() => import("routes/ComplianceRoute/ComplianceItemRoute"));

const FallBack = () => (
  <Box display="flex" justifyContent="center" bgcolor="background.default" height="100vh">
    <Loading marginTop={35} />
  </Box>
);

const AppRoutes = () => (
  <Suspense fallback={<FallBack />}>
    <Routes>
      <Route index element={<LazyIndexRoute />} />

      <Route element={<AuthFlow />}>
        <Route path={LOGIN_ROUTE} element={<LazySignInRoute />} />
        <Route path={LOGIN_ORG_ROUTE} element={<LazySignInOrgRoute />} />
        <Route path={ACCEPT_INVITATION_ROUTE} element={<LazyAcceptInviteRoute />} />
        <Route path={SIGNUP_ROUTE} element={<LazySignUpRoute />} />
        <Route path={SIGNUP_VALIDATE_INVITATION_ROUTE} element={<LazyInviteCodeRoute />} />
        <Route path={SIGNUP_CREATE_ORG} element={<LazySignUpOrgRoute />} />
      </Route>

      <Route element={<LoggedInFlow />}>
        <Route path={DASHBOARD_ROUTE} element={<LazyDashboardRoute />} />
        <Route path={DASHBOARD_BY_ID_ROUTE} element={<LazyDashboardByIdRoute />} />
        <Route
          path={DASHBOARD_HISTORICAL_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.HISTORICAL_DASHBOARD,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyDashboardRoute historical />}
            />
          }
        />
        <Route path={ISSUE_BY_ID_ROUTE} element={<LazyIssueRoute />} />
        <Route path={ISSUES_BY_RESOURCE_ID_ROUTE} element={<LazyIssuesByResourceIdRoute />} />
        <Route path={ISSUES_BY_RULE_ID_ROUTE} element={<LazyIssuesByRuleIdRoute />} />
        <Route path={UNDO_REMEDIATION_ROUTE} element={<LazyUndoRemediationRoute />} />
        <Route
          path={CLOUD_MAP_ACCOUNTS_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.CLOUDMAP_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyCloudMapRoute type={CLOUD_MAP_TYPES.ACCOUNTS} />}
            />
          }
        />
        <Route path={CLOUD_MAP_ASSETS_ROUTE} element={<LazyCloudMapRoute type={CLOUD_MAP_TYPES.ASSETS} />} />
        <Route path={DEPENDABOT_ROUTE} element={<LazyDependabotRoute />} />
        <Route
          path={RULES_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.RULES_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyRulesRoute />}
            />
          }
        />
        <Route
          path={COMPLIANCE_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.COMPLIANCE_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyIssuesComplianceRoute />}
            />
          }
        />
        <Route
          path={COMPLIANCE_BY_ID_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.COMPLIANCE_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyIssuesComplianceByIdRoute />}
            />
          }
        />
        <Route
          path={EVENTS_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.EVENTS_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyEventsRoute />}
            />
          }
        />
        <Route
          path={EVENT_BY_ID_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.EVENTS_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyEventRoute />}
            />
          }
        />
        <Route
          path={INVENTORY_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.INVENTORY_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyInventoryRoute />}
            />
          }
        />
        <Route path={INTEGRATIONS_ROUTE} element={<LazyIntegrationsRoute />} />
        <Route
          path={INVENTORY_BY_RESOURCE_ID_ROUTE}
          element={
            <RequirePerm
              access={{
                feature: FEATURE.INVENTORY_PAGE,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyInventoryByResourceIdRoute />}
            />
          }
        />
        <Route
          path={ACCOUNTS_ROUTE}
          element={
            <RequirePerm
              access={{
                permission: PERMISSION_TYPES.ADMIN,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyAccountsRoute />}
            />
          }
        />
        <Route
          path={USERS_ROUTE}
          element={
            <RequirePerm
              access={{
                permission: PERMISSION_TYPES.ADMIN,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyUsersRoute />}
            />
          }
        />
        <Route
          path={NOTIFICATIONS_ROUTE}
          element={
            <RequirePerm
              access={{
                permission: PERMISSION_TYPES.ADMIN,
                fallback: <LazyNotFoundRoute />,
              }}
              element={<LazyNotificationsRoute />}
            />
          }
        />

        <Route path="*" element={<LazyNotFoundRoute />} />
      </Route>
    </Routes>
  </Suspense>
);

export default AppRoutes;
