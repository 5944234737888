import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";

import { FEATURE, INTEGRATION, Integration } from "store/types";
import {
  apiKeyFields,
  awsCostFields,
  gcpCostFields,
  githubFields,
  IntegrationItem,
} from "routes/IntegrationsRoute/components";
import { EmailSvg, GithubSvg, SlackSvg, TerraformSvg, ApiKeySvg, AWSCostSvg, GCPCostSvg } from "components/icons";
import { RootStore } from "store/root.store";
import { TableFields, TableItemProps } from "components/ui";
import { getNotificationFields } from "routes/NotificationsRoute/components/notificationChannelsTable";

export const integrationsList: Integration[] = [
  {
    type: INTEGRATION.SLACK,
    title: "Slack",
    icon: <SlackSvg />,
    comment: "Send notifications to channels and create projects",
  },
  {
    type: INTEGRATION.EMAIL,
    title: "Email",
    comment: "Send notifications to email",
    icon: <EmailSvg />,
  },
  /*
  {
    type: INTEGRATION.JIRA,
    title: "Jira",
    comment: "Plan, track and release great software",
    icon: <JiraSvg />,
  },
  */
  /*
  {
    type: INTEGRATION.HTTP,
    title: "HTTP",
    comment: "Send notifications over HTTP",
    icon: <HttpSvg />,
  },
  */
  {
    type: INTEGRATION.TERRAFORM,
    title: "Terraform",
    comment: "Integrate your Terraform account",
    icon: <TerraformSvg />,
  },
  {
    type: INTEGRATION.GITHUB,
    title: "Github",
    comment: "Add your Github account",
    icon: <GithubSvg />,
  },
  {
    type: INTEGRATION.API_KEY,
    title: "API Access",
    comment: "Generate an API key",
    icon: <ApiKeySvg />,
  },
  {
    type: INTEGRATION.AWS_COST,
    title: "AWS Cost",
    comment: "Tracks your AWS usage and provides estimated charges associated with your account",
    icon: <AWSCostSvg />,
  },
  {
    type: INTEGRATION.GCP_COST,
    title: "GCP Cost",
    comment: "Tracks your GCP usage and provides estimated charges associated with your account",
    icon: <GCPCostSvg />,
  },
];

const IntegrationsRoute = ({ store }: { store?: RootStore }) => {
  const { isFetched: isGithubFetched, fetch: githubFetch, installations: githubInstallations } = store!.github;
  const { fetchApiKeys, apiKeys } = store!.apiKeys;
  const { fetchIntegrations, awsIntegrations, gcpIntegrations, notAddedAwsCostAccounts, notAddedGcpCostAccounts } =
    store!.costReport;
  const { isFetched: isFetchedNotificationChannels, fetch: fetchNotificationChannels, channels } = store!.notifications;

  const integrationTerraformEnabled = !!store?.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_TERRAFORM);
  const integrationAwsEnabled = !!store?.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_AWS_COST);
  const integrationGcpEnabled = !!store?.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_GCP_COST);
  const apiKeyEnabled = !!store?.auth.isFeatureEnabled(FEATURE.API_ACCESS);

  const integrations: Record<
    string,
    {
      fields: TableFields[];
      data: TableItemProps[];
      type: INTEGRATION;
      noMoreConnects?: boolean;
    }
  > = {
    ...[INTEGRATION.SLACK, INTEGRATION.EMAIL, INTEGRATION.JIRA, INTEGRATION.HTTP].reduce(
      (acc, type) => ({
        ...acc,
        [type]: {
          fields: getNotificationFields(true),
          type,
          data: channels.filter((c) => c.channel_type === type),
        },
      }),
      {},
    ),
    [INTEGRATION.GITHUB]: {
      fields: githubFields,
      type: INTEGRATION.GITHUB,
      data: githubInstallations.map((item) => ({
        id: item.installationId.toString(),
        ...item,
      })),
    },
    [INTEGRATION.API_KEY]: {
      fields: apiKeyFields,
      type: INTEGRATION.API_KEY,
      data: apiKeys,
    },
    [INTEGRATION.AWS_COST]: {
      fields: awsCostFields,
      type: INTEGRATION.AWS_COST,
      data: awsIntegrations,
      noMoreConnects: !notAddedAwsCostAccounts.length,
    },
    [INTEGRATION.GCP_COST]: {
      fields: gcpCostFields,
      type: INTEGRATION.GCP_COST,
      data: gcpIntegrations,
      noMoreConnects: !notAddedGcpCostAccounts.length,
    },
  };

  useEffect(() => {
    if (!isGithubFetched) githubFetch();
  }, [isGithubFetched, githubFetch]);

  useEffect(() => {
    if (!isFetchedNotificationChannels) fetchNotificationChannels();
  }, [isFetchedNotificationChannels, fetchNotificationChannels]);

  useEffect(() => {
    fetchApiKeys();
  }, [fetchApiKeys]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  return (
    <Box display="flex" gap={5} flexDirection="column">
      <Typography variant="h1">Integrations</Typography>

      <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap={5}>
        <Box>
          <Typography component="div" marginBottom={1.5} variant="title" fontSize={24}>
            Connected apps
          </Typography>
          <Typography variant="table">Select and connect tools you use to integrate with your workflow </Typography>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" margin={-2.5}>
        {integrationsList
          .filter((integration) => integrationAwsEnabled || integration.type !== INTEGRATION.AWS_COST)
          .filter((integration) => integrationGcpEnabled || integration.type !== INTEGRATION.GCP_COST)
          .filter((integration) => integrationTerraformEnabled || integration.type !== INTEGRATION.TERRAFORM)
          .filter((integration) => apiKeyEnabled || integration.type !== INTEGRATION.API_KEY)
          .map((integration) => (
            <IntegrationItem key={integration.type} integration={integration} {...integrations[integration.type]} />
          ))}
      </Box>
    </Box>
  );
};

export default inject("store")(observer(IntegrationsRoute));
